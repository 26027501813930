<template>
  <div>
    <van-sticky>
      <head-bar type="primary" title="历届回顾" @onClickLeft="onClickLeft" />
    </van-sticky>
    <div class="history-container">
      <div class="history-head">
        <div class="logo">
          <img :src="require('@/assets/images/banner/logo.png')" alt="">
          岳麓峰会
        </div>
        <van-divider :hairline="false" content-position="left">历届回顾</van-divider>
        
        <!-- <van-divider></van-divider> -->
        <van-tabs v-model="active">
          <van-tab title="历届回顾"></van-tab>
          <van-tab title="大佬语录"></van-tab>
        </van-tabs>
      </div>
      <transition name="meeting">
        <div v-if="active === 0">
          <div class="meeting-item" v-for="item in meetingList" :key="item.id" @click="viewMeeting(item)">
            <img :src="item.img" alt="">
            <div class="name">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </transition>
      <transition name="government">
        <div v-if="active === 1">
          <div class="famous-item" v-for="item in dalaoList" :key="item.id" @click="viewFamous(item)">
            <div class="name">{{ item.name }}</div>
            <img :src="item.img+'?imageView2/2/w/750'" alt="">
          </div>
        </div>
      </transition>
      <float-menu />
    </div>
  </div>
</template>

<script>
import floatMenu from '@/components/floatMenu/index'
import headBar from '@/components/headBar/headBar'
export default {
  name: 'historyMeeting',
  components: { floatMenu, headBar },
  data() {
    return {
      active: 0,
      meetingList: [
        {
          id: 10,
          name: '2023岳麓峰会——智联湘江 乘数而上',
          desc: '6月19日上午，2023互联网岳麓峰会在长沙开幕。省委书记沈晓明致辞并宣布开幕，省委副书记、省长毛伟明主持。（显示一行就行后面就是省略号）',
          link: 'https://mp.weixin.qq.com/s/w9FqGuelKMYI3ZQlJZeQ7A',
          img: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202408241640104480845.jpg'
        },
        {
          id: 9,
          name: '2022岳麓峰会——创新强省会、数字向未来',
          desc: '九年峰会，历久弥新全球互联网再度进入“长沙时间”来自互联网行业、投资界、学术界的专家大咖现场发表主旨演讲，圆桌论坛围绕“强省会”战略，聚焦重点领域，从智能制造，创新引领、数字赋能、开放融通等方面展开高峰对话。',
          link: 'https://mp.weixin.qq.com/s/47Ot0QnwsGY0lHcHpaoRYA',
          img: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306042300025431443.jpg'
        },
        {
          id: 8,
          name: '2021岳麓峰会——智链新百年，云聚新高地',
          desc: '4月16日上午，2021岳麓峰会在长沙开幕。省委书记、省人大常委会主任许达哲宣布开幕，省委副书记、省长毛伟明致辞。开幕式前，许达哲、毛伟明会见了出席峰会的嘉宾代表。省领导乌兰、张剑飞、张宏森、陈飞出席开幕式或参加会见，省委常委、长沙市委书记吴桂英主持开幕式。张平文、徐直军、熊晓鸽、姚劲波等专家学者、互联网企业领军人物、知名投资机构负责人出席开幕式或参加会见。',
          link: 'https://mp.weixin.qq.com/s/yF-mGwhsp1HSsx00cgmMQw',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202203081054040331193.png'
        },
        {
          id: 7,
          name: '2020岳麓峰会——数字新经济 云开看未来',
          desc: '七届峰会、七年精彩。9月7日-9月9日，以“数字新经济 云开看未来”为主题的2020岳麓峰会在长沙成功举办。中央网信办副主任、国家网信办副主任盛荣华出席开幕式并致辞。省领导胡衡华、谢建辉、张宏森、陈飞，中国网络社会组织联合会秘书长赵晖出席开幕式或致辞。',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449583&idx=1&sn=d8d573b648080651f8e952b3bf06d1aa&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071057590801431.png'
        },
        {
          id: 6,
          name: '2019移动岳麓峰会——智能网联 于斯为盛',
          desc: '本届峰会由国家工业和信息化部、湖南省人民政府指导，湖南省委网信办、湖南省教育厅、湖南省工业和信息化厅、湖南湘江新区管委会、湖南广播电视台主办，长沙高新区管委会承办。',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447765&idx=1&sn=bd5b7601d398a7514484114f757c440b&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071057445658036.png'
        },
        {
          id: 5,
          name: '2018移动岳麓峰会——智能互联 于斯为盛',
          desc: '新视野、新举措、新发展、新峰会，2018年春天，我们第五次迎来了岳麓峰会。麓山脚下，这场具有浓郁“湖湘味”的互联网盛会，已然升级到了2.0版。伴随着2018岳麓峰会全新升级，“东有乌镇，春有岳麓”这一佳话以网速传递开去，2018岳麓峰会“一票难求”。',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447275&idx=1&sn=cab037a6d5bd0871c29165e6613a68c2&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071057248559334.png'
        },
        {
          id: 4,
          name: '2017移动岳麓峰会——移动风云 于斯为盛',
          desc: '此次峰会以“共享经济下的移动生活”为主题，分为主论坛、项目路演等分论坛，除开幕式外，还推出了共享经济下移动岳麓峰会大咖说、共享经济之移动互联网湘军聚、共享经济之小饭桌直播、共享经济之移动互联网星锐秀、项目路演、移动生活创业服务专场、移动生活征信+消费金融专场、移动生活大数据专场、移动生活数字资产专场、移动生活旅游专场等11个环节',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447275&idx=2&sn=08fb73d9c1d43aeb98f39a3cd4114ffd&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071057017992631.png'
        },
        {
          id: 3,
          name: '2016移动岳麓峰会——移动生活 于斯为盛',
          desc: '熊晓鸽、何志涛、王填等“大佬”进行主题发言探讨移动生活新动力、“互联网+”新风向，肖冰、刘纲、贾博等30余位移动互联网精英就互联网+投资、互联网+教育、互联网+金融、互联网+游戏等涵盖移动生活各方面的热门话题论道，为大家带来最丰富、最前沿、最深刻的行业观点，最干货、最实用的经验分享。',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447275&idx=3&sn=27298e312a03fb771756e24bc51b7cce&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071056435656626.png'
        },
        {
          id: 2,
          name: '2015移动岳麓峰会——惟“网”有才 于斯为盛',
          desc: '此次峰会为期二天，设开幕式、项目签约、湘军论坛、项目路演以及企业主题活动。﻿﻿峰会吸引了1600多家省内、外企业报名参加。大会邀请了国家部委及省、市领导、移动互联网业界大佬、行业领袖、湘商代表、院校专家、投融资机构代表等约2200人参加，是长沙市2015年重点开展移动互联网产业环境推介及项目招商的大型活动之一。',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447275&idx=4&sn=57466e0ffb02b986b4a09e254f40b2bc&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071056240375475.png'
        },
        {
          id: 1,
          name: '2014移动岳麓峰会——指尖世界 成就梦想',
          desc: '峰会现场，熊晓鸽、姚劲波、李新宇、王填、戴跃锋等移动互联网创业团队，围绕“80后、90后之移动创业”“企业如何迎接移动互联网时代的到来”“如何审视移动互联网对传统行业的渗透、改变甚至颠覆”等问题，进行了剖析和探讨。',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447275&idx=5&sn=65e89c3e6d35b043056a5d59575ed2ea&scene=19#wechat_redirect',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071055550668016.png'
        }
      ],
      dalaoList: [
        {
          id: 14,
          name: '嘉宾金句——2023岳麓峰会',
          img: 'https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202408251153014065029.jpg',
          link: 'https://mp.weixin.qq.com/s/tb2358qDvcWivsPYB3Nr2w'
        },
        {
          id: 1,
          name: '大佬语录——李彦宏',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104091303399422722.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449654&idx=6&sn=de2e74269cfce0be88035e49b79a28fd&scene=19#wechat_redirect'
        },
        {
          id: 2,
          name: '大佬语录——邱跃鹏',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104091304478171979.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449654&idx=1&sn=c367573b280fbbcb96c0dfc4f8d4764e&scene=19#wechat_redirect'
        },
        {
          id: 3,
          name: '大佬语录——高红冰',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104091305409871120.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449654&idx=2&sn=aabd3cd66d5b7a12a1bbab4bc16d6cd2&scene=19#wechat_redirect'
        },
        {
          id: 4,
          name: '大佬语录——高同庆',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104091306417778223.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449654&idx=3&sn=ef88ec79409811625fd1a4a546dfd693&scene=19#wechat_redirect'
        },
        {
          id: 5,
          name: '大佬语录——马潍',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104091307390871214.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449654&idx=4&sn=3d3c1370bf00785099e10f5bf071eaea&scene=19#wechat_redirect'
        },
        {
          id: 6,
          name: '大佬语录——徐新',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104091308334687325.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502449654&idx=5&sn=a8c7d529617875bd99d8d7adcf0c40a1&scene=19#wechat_redirect'
        },
        {
          id: 7,
          name: '大佬语录——熊晓鸽',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081846402725566.png',
          link: 'http://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=1&sn=e738dad299c984deacf30f8d4b24d140&scene=19#wechat_redirect'
        },
        {
          id: 8,
          name: '大佬语录——姚劲波',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081846574191568.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=2&sn=1bc5814705e1cbfabcd3ea98157bdbc5&scene=19#wechat_redirect'
        },
        {
          id: 9,
          name: '大佬语录——倪正东',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081847128344692.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=3&sn=af9a5c932e485e17ec6c00c7b878969d&scene=19#wechat_redirect'
        },
        {
          id: 10,
          name: '大佬语录——王刚',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081847294584512.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=4&sn=4a373d6a19a7d7be8c4738ea532c4197&scene=19#wechat_redirect'
        },
        {
          id: 11,
          name: '大佬语录——徐小平',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081847448018276.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=5&sn=d0f2c74e8d9ce6189d74aa2b71c5b6c9&scene=19#wechat_redirect'
        },
        {
          id: 12,
          name: '大佬语录——唐岩',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081848004430137.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=6&sn=32000006850007ba75d9409fb9427770&scene=19#wechat_redirect'
        },
        {
          id: 13,
          name: '大佬语录——奉佑生',
          img: 'https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104081848132813769.png',
          link: 'https://mp.weixin.qq.com/s?__biz=MzI3NTE2NDU0NQ==&mid=502447272&idx=7&sn=210d33aee5a1c03e553f08e3d5bde0ca&scene=19#wechat_redirect'
        }
      ]
    }
  },
  methods: {
    viewMeeting(item) {
      window.location.href = item.link
    },
    viewFamous(item) {
      window.location.href = item.link
    },
    onClickLeft() {
      this.$router.push('/index/' + this.$store.state.miceInfo.miceLink || this.$route.params.miceLink)
    }
  }
}
</script>

<style lang="less" scoped>
.history-container{
  padding: 15px;
  height: 100vh;
  overflow-y: auto;
  background: no-repeat center/100% 102% url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306062004234495727.jpg');
  color: #fff;
  .history-head{
    padding: 8px;
    display: flex;
    flex-direction: column;
    background: no-repeat center/100% 100% url('https://zs202010-1300602059.cos.ap-guangzhou.myqcloud.com/202104071053361981337.png');
    background-color: #22458f;
    border-radius: 8px;
    font-size: 15px;
    /deep/.van-tabs__nav{
      background-color: transparent;
      .van-tab{
        color: #fff;
      }
    }
    /deep/.van-tabs__line{
      background-color: #fff;
    }
    .van-divider{
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
    .logo{
      font-size: 13px;
      display: flex;
      align-items: center;
      img{
        height: 45px;
        width: 45px;
      }
    }
  }
  .meeting-item{
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    font-size: 14px;
    color: #333;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.05);
    img{
      height: 150px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
    .name{
      font-weight: bold;
      // margin-bottom: 5px;
      padding: 5px 0;
    }
    .desc{
      font-size: 12px;
      color: #666;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 5px 0;
    }
  }
  .famous-item{
    padding: 10px;
    background-color: rgba(255, 255, 255, .95);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 120px;
    border-radius: 8px;
    margin-top: 15px;
    .name{
      font-size: 14px;
      color: #333;
    }
    img{
      height: 80px;
      width: 120px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
}
.famous-conatiner{
  margin-top: 15px;
  background-color: rgba(255,255,255,.84);
  border-radius: 8px;
  padding: 10px;
}
.meeting-enter-active,
.meeting-leave-active,
.government-enter-active,
.government-leave-active{
  position: absolute;
  width: calc(100% - 30px);
  transition: all .4s ease
}
.government-enter,
.government-leave-to{
  opacity: .1;
  transform: translateX(100%);
}
.meeting-enter,
.meeting-leave-to{
  opacity: 0;
  transform: translateX(-100%);
}
</style>